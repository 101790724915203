


















































import useMisc from "@/use/misc";
import { defineComponent } from "@vue/composition-api";
import countriesList from "@/assets/data/countries.json";

export default defineComponent({
  props: {
    data: {
      type: Object,
      required: true,
    },
    image: {
      type: String,
      required: false,
    },
  },

  setup(_, { root }) {
    const { getLanguageVersion } = useMisc({ root });
    return { countriesList, getLanguageVersion };
  },
});
